<template>
  <div class="chat-contents">
    <div v-for="message in messages" :key="message.id" class="chat-row">
      <div class="chat-message"
           :class="{ 'message-out': isOutgoing(message), 'message-in': message.type_user === 'CLIENT' }">
        <div class="chat-bubble">
          <div v-if="isOutgoing(message)" class="chat-name">
            <span v-if="message.type_user === 'AGENT'">{{ message.agent }}</span>
            <span v-else>Automático</span>
          </div>
          <div v-for="(attachment, i) in message.attachments" :key="i" class="chat-image">
            <img v-if="attachment.type === 'image'" :src="attachment.uri">
            <video
              v-else-if="attachment.type === 'video'"
              :src="attachment.uri"
              :type="attachment.media_type"
              controls="true"
            ></video>
            <audio
              v-else-if="attachment.type === 'audio'"
              :src="attachment.uri"
              :type="attachment.media_type"
              controls
              preload="metadata"
            ></audio>
            <a
              v-else
              :href="attachment.uri"
              target="_blank"
              class="chat-document"
            >
              <span class="material-icons-outlined">
                description
              </span>
              <div class="chat-document-info">
                <div>{{ attachment.name }}</div>
                <small>{{ attachment.media_type || attachment.type }}</small>
              </div>
            </a>
          </div>
          <div v-if="message.message" class="chat-text">
            {{ message.message }}
          </div>
        </div>
        <div class="chat-time">
          {{ relativeDate(message.creation_date) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ChatContentComponent',
  components: {},
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  methods: {
    relativeDate(date) {
      moment.locale('pt_BR');
      return moment(date).fromNow();
    },
    isOutgoing(message) {
      return ['AGENT', 'BOT'].includes(message.type_user);
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-contents {
  padding: 30px 15px;

  .chat-message {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;

    &.message-in {
      align-items: flex-start;

      .chat-bubble {
        background-color: #F2F4F7;
        color: #101828;
      }
    }

    &.message-out {
      align-items: flex-end;

      .chat-bubble {
        background-color: var(--clr-yup-purple);
        color: #fff;
      }
    }

    .chat-bubble {
      border-radius: 8px;
      padding: 3px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      max-width: 65%;

      .chat-name {
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
        padding:8px 16px 0;
      }

      .chat-image {
        width: 336px;

        video {
          width: 100%;
        }

        .chat-document {
          display: flex;
          padding: 13px 19px;
          color: inherit;

          .material-icons-outlined {
            font-size: 43px;
          }

          .chat-document-info {
            margin-right: 10px;
            margin-left: 10px;
          }
        }
      }

      .chat-text {
        padding: 8px 16px;
      }
    }

    .chat-time {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
</style>
