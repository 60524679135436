<template>
  <div>
    <div v-if="fetching" class="text-center text-primary my-2">
      <span aria-hidden="true" class="align-middle spinner-border"></span>
    </div>
    <div v-else>
      <div class="row toolbar">
        <AvatarComponent :image="contact.profile_picture"
                         :name="`${contact.first_name} ${contact.last_name}`"></AvatarComponent>
        <div class="col-8 info">
          <div class="row">
            <strong>{{ contact.first_name }} {{ contact.last_name }}</strong>
            <small class="date">Via {{ channel }} ∙ {{ formatDate(creation_date) }}</small>
            <BadgeComponent variant="light" @click="copyProtocol(protocol)">
              <span class="material-symbols-outlined">content_copy</span>
              {{ protocol }}
            </BadgeComponent>
          </div>
          <div class="row contact-data">
            <small>{{ contact.email }} - {{ contact.mobile_number }}</small>
          </div>
        </div>
      </div>
      <ChatContentComponent ref="chat" :messages="messages"></ChatContentComponent>
    </div>
    <ContactSidebarComponent ref="contact" :contact="contact"></ContactSidebarComponent>
  </div>
</template>

<script>
import AvatarComponent from '@/components/ui/AvatarComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import ChatContentComponent from '@/components/chat/ChatContentComponent.vue';
import moment from 'moment-timezone';
import ContactSidebarComponent from '@/components/contacts/ContactSidebarComponent.vue';

export default {
  name: 'RoomView',
  components: {
    ContactSidebarComponent,
    AvatarComponent,
    BadgeComponent,
    ChatContentComponent,
  },
  data() {
    return {
      fetching: false,
      channel: '',
      protocol: '',
      contact: {},
      messages: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.fetching = true;
      this.$store.dispatch('fetchOmniRoom', this.$route.params.roomId).then(
        (room) => {
          this.protocol = room.protocol;
          this.channel = room.channel;
          this.creation_date = room.creation_date;
          this.contact = room.contact;
          this.messages = room.messages;

          if (room.channel === 'whatsapp-java') this.channel = 'WhatsApp';
          else if (room.channel === 'widget-java') this.channel = 'Widget';
          else this.channel = room.channel.charAt(0).toUpperCase() + room.channel.slice(1);
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      ).finally(
        () => {
          this.fetching = false;
        },
      );
    },
    formatDate(date) {
      return moment(date).tz('America/Sao_Paulo').format('MMMM DD - HH:mm');
    },
    copyProtocol(protocol) {
      navigator.clipboard.writeText(protocol).then(() => {
        this.$toast.show({
          title: this.$t('live.copied'),
          type: 'success',
        });
      });
    },
    showContact() {
      this.$refs.contact.toggle();
    },
    exportData(format) {
      const messages = this.messages.map((message) => ({
        creation_date: message.creation_date,
        type_user: message.type_user,
        message: message.message,
        attachment: message.attachments.length ? message.attachments[0].uri : '',
      }));
      if (format === 'csv') {
        this.exportToCsv(messages);
      } else if (format === 'xlsx') {
        this.exportToExcel(messages);
      } else {
        this.exportToPDF(messages);
      }
    },
    exportToCsv(messages) {
      const rows = [];
      messages.forEach((message) => {
        if (rows.length === 0) {
          rows.push(Object.keys(message).join(';'));
        }
        rows.push(`"${Object.values(message).join('";"')}"`);
      });
      this.download(
        `data:text/csv; charset=utf-8, ${encodeURIComponent(rows.join('\n'))}`,
        'teste',
        '.csv',
      );
    },
    exportToExcel(messages) {
      const html = this.renderTable(messages).replace(/ /g, '%20');
      this.download(
        `data:application/vnd.ms-excel, ${html}`,
        'teste',
        '.xls',
      );
    },
    exportToPDF(messages) {
      const html = this.renderTable(messages);
      const win = window.open('', '');
      win.document.body.innerHTML = html;
      win.print();
      win.close();
    },
    renderTable(messages) {
      let html = '<table>';
      messages.forEach((message, i) => {
        if (i === 0) {
          html += `<thead><tr><th>${Object.keys(message).join('</th><th>')}</th></tr></thead><tbody>`;
        }
        html += `<tr><td>${Object.values(message).join('</td><td>')}</td></tr>`;
      });
      html += '</tbody></table>';

      return html;
    },
    download(href, filename, ext) {
      const downloadLink = document.createElement('a');
      downloadLink.href = href;
      downloadLink.download = (filename ?? this.w.globals.chartID) + ext;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
  padding: 0px 10px 20px;

  .info {
    margin-left: 10px;

    strong {
      font-weight: 600;
    }

    small {
      color: rgb(67, 85, 108);
      line-height: 1.9;
    }

    .date {
      margin-left: 5px;
    }

    .badge {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
</style>
